<template>
  <v-container>

    <!-- Row for showing time range and range selector -->
    <v-card flat outlined rounded="xl" class="my-2 pa-2" @click="onTimeRangeClick">
      <div class="px-2 d-flex justify-space-between align-center"  v-if="tab == 'orders'">
        <div class="text-body-1">
          {{ orders.dates[0] | toDateString }} - {{ orders.dates[1] | toDateString }}
        </div>

        <div class="d-flex flex-column align-end">
          <div class="d-flex align-center text-body-1 font-weight-bold" v-if="orders.data['totalConfirmedAmount']">
            NT${{ orders.data['totalConfirmedAmount'] - getTotalPointsByPointCode(1) }}
          </div>
          <div class="d-flex align-center text-body-1 font-weight-bold">
            <CustomIcon :src="getPointsIconSrc()" :width="24" class="mr-2"></CustomIcon>
            <!-- <span>{{ orders.data['totalConfirmedAmount'] || 0 }}</span> -->
            <span>{{ getTotalPointsByPointCode(1) }}</span>
          </div>
        </div>
      </div>
    </v-card>

    <!-- Query Search By Phone -->
    <div class="my-2 d-flex justify-space-between align-center">
      <div class="flex-shrink-1">
        <v-btn large text class="text-subtitle-1" @click="onSearchOrdersClick">
          <v-icon left>mdi-magnify</v-icon>
          <div>
            <span v-if="orders.search.length">{{ orders.search }}</span>
            <span v-else>搜尋&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </v-btn>
        <v-btn icon class="mr-1" v-show="orders.search.length"
          @click="clearSearchOrders">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="mr-2">
        <v-btn icon @click="forceOrdersRefresh" :disabled="orders.refreshLoading">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- List of orders -->
    <SSRTable ref="list-orders" :hideHeaders="true"
      :data-url="fetchOrdersUrl" :data-key="'orders'" :cols="orders.cols"
      @click-row="onOrdersRowClicked">
      
      <div slot="psIcon" slot-scope="{ item }">
        <div class="d-flex align-center">
          <CustomIcon :src="getPaymentIcon(item.paymentService, item.paymentType)" :width="36" v-if="item.paymentMethod == 'online'">
          </CustomIcon>
          <CustomIcon :src="getPointsIconSrc()" :width="36" v-else>
          </CustomIcon>
        </div>
      </div>

      <div slot="left" slot-scope="{ item }">
        <span class="text-subtitle-2">{{ item.consumerName }} - {{ item.consumerPhone }}</span><br>
        <span class="grey--text">{{ item.createdAt | tsToDateString }}</span>
      </div>
      <div slot="right" slot-scope="{ item }">
        <div class="d-flex justify-end align-center">
          <span class="text-body-2 mr-1" v-if="item.status == 'cancelled'">
            已取消
          </span>

          <div class="d-flex flex-column align-end text-subtitle-1 font-weight-bold" v-if="item.status == 'done'">
            <span v-if="item.paymentAmount">
              NT${{ item.paymentAmount }}
            </span>
            <div class="d-flex align-center" v-if="item.pointAmount">
              <CustomIcon :src="getPointsIconSrc()" :width="24"></CustomIcon>
              <span>&nbsp;&nbsp;{{ item.pointAmount }}</span>
            </div>
            <!-- <span class="red--text text-decoration-line-through" v-else-if="item.status == 'cancelled'">
              {{ item.pointAmount }}
            </span> -->
          </div>

        </div>
      </div>
    </SSRTable>

    <!-- A dummy spacing at the end of list FIXME: to avoid content overlapping -->
    <div style="height: 72px;">
    </div>

    <!-- Dialog - Order Search -->
    <v-dialog v-model="dialog.search.show">
      <v-card>
        <v-card-title>搜尋電話號碼</v-card-title>
        <v-card-text>
          <v-text-field dense filled rounded hide-details type="number"
            prepend-inner-icon="mdi-magnify" clearable
            v-model="orders.search">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary"
            @click="fetchOrders(); dialog.search.show = false;">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Order Query Time Range picker -->
    <v-dialog v-model="dialog.timeRange.show">
      <v-card>
        <v-card-title>搜尋交易時間區間</v-card-title>
        <v-card-text>
          <DateRangePicker v-model="orders.dates" :shortcuts="orders.dateRangePickerShortcuts"></DateRangePicker>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="fetchOrders(); dialog.timeRange.show = false">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Order transaction detail -->
    <v-dialog v-model="dialog.order.show">
      <v-card>
        <v-card-title>交易明細</v-card-title>
        <v-card-text>
          <!-- <div class="d-flex flex-column">
            <CustomIcon :src="require('@/assets/icons/point.svg')" class="mx-auto my-3" :width="64"></CustomIcon>
            <span class="text-center text-h5 font-weight-bold"
              :class="{ 'lightgreen--text': dialog.order.data.orderStatus == 'confirmed',
                'red--text': dialog.order.data.orderStatus == 'cancelled' }">
              {{ dialog.order.data.pointAmount }}
            </span>
          </div> -->
          <div class="d-flex justify-space-around">
            <div class="d-flex align-center" v-if="dialog.order.data.pointAmount">
              <CustomIcon :src="getPointsIconSrc()" class="mx-auto my-3" :width="48"></CustomIcon>
              <span class="ml-2 text-h5 white--text font-weight-bold">{{ dialog.order.data.pointAmount }}</span>
            </div>
            <div class="d-flex align-center" v-if="dialog.order.data.paymentAmount">
              <span class="ml-2 text-h5 white--text font-weight-bold">
                NT${{ dialog.order.data.paymentAmount }}
              </span>
            </div>
          </div>
          

          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td style="width:40%;">交易時間</td>
                  <td>{{ dialog.order.data.createdAt | tsToDateStringMore }}</td>
                </tr>
                <tr>
                  <td>支付方式</td>
                  <td>
                    <div class="py-2 d-flex justify-center align-center">
                      <CustomIcon :src="getPaymentIcon(dialog.order.data.paymentService, dialog.order.data.paymentType)"
                        :width="36"
                        v-if="dialog.order.data.paymentMethod == 'online'">
                      </CustomIcon>
                      <CustomIcon :src="getPointsIconSrc()" :width="36" v-else>
                      </CustomIcon>
                      <span class="text-subtitle-1" v-if="dialog.order.data.paymentMethod == 'offline'">&nbsp;現場付款</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>消費者</td>
                  <td>{{ dialog.order.data.consumerName }}</td>
                </tr>
                <tr>
                  <td>消費者電話</td>
                  <td>{{ dialog.order.data.consumerPhone }}</td>
                </tr>
                <tr>
                  <td>交易特店</td>
                  <td>{{ dialog.order.data.storeName }}</td>
                </tr>
                <tr>
                  <td>訂單編號</td>
                  <td>{{ dialog.order.data.orderId }}</td>
                </tr>
                <tr>
                  <td>訂單狀態</td>
                  <td>{{ getOrderStatusLiteral(dialog.order.data) }}</td>
                </tr>

                <template v-if="dialog.order.data.cancelledAt && dialog.order.data.cancelledBy">
                  <tr>
                    <td>取消時間</td>
                    <td>{{ dialog.order.data.cancelledAt | tsToDateStringMore }}</td>
                  </tr>
                  <tr>
                    <td>取消操作者</td>
                    <td>{{ dialog.order.data.cancelledBy }}</td>
                  </tr>
                </template>
                
                <!-- <tr>
                  <td>提領狀態</td>
                  <td>{{ dialog.order.data.settlementStatus | toSettlementStatusLiteral }}</td>
                </tr> -->
                <!-- <template v-if="dialog.order.data.orderStatus == 'cancelled'">
                  <tr>
                    <td>退點時間</td>
                    <td>{{ dialog.order.data.cancelledTs | tsToDateStringMore }}</td>
                  </tr>
                  <tr>
                    <td>退點操作者</td>
                    <td>{{ dialog.order.data.cancelledBy }}</td>
                  </tr>
                </template> -->
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-btn rounded color="secondary" class="flex-grow-1" style="color:red;"
            @click="cancelOrder"
            :disabled="dialog.order.data.status != 'done'">
            取消訂單
          </v-btn>
          <v-btn rounded color="primary" class="flex-grow-1" @click="dialog.order.show = false">
            &nbsp;&nbsp;確定&nbsp;&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';

import SSORequest from '@/sso-request';
import PlatformParams from '@/mixins/platform-params';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable, DateRangePicker
  },
  props: {
    storeId: String
  },
  mixins: [ PlatformParams ],
  
  mounted() {
    // Resolve store ID from query.
    // this.storeId = this.$route.query.storeId;

    // Resolve default tab.
    if (this.$route.query.openTab)
      this.tab = this.$route.query.openTab;

    // Load necessary data.
    if (this.storeId) {
      this.fetchStoreInfo();
      this.fetchOrders();
      console.warn(this.storeRoleId);
    }
  },

  methods: {

    fetchStoreInfo() {
      // Obtain points settings and values.
      this.$store.dispatch('fetchPointServicesByStoreId', this.storeId)
      .then(data => {
        console.log(`[TRANSACTIONS]<DEBUG> fetchStoreInfo: got cached points data`, data);
        this.points = data;

        // Populate periods array.
        this.periods.rows = [ ];
        this.points.forEach(p => {
          if (!p.pointPeriods) return;
          p.pointPeriods.forEach(pp => {
            this.periods.rows.push(pp);
          })
        });
      });
    },

    fetchOrders() {
      setTimeout(() => {
        this.$refs['list-orders'].loadData()
        .then(data => {
          this.orders.refreshLoading = false;
          this.orders.data = data;
        });
      });
    },

    onOrdersRowClicked(order) {
      console.log(`[TRANSACTIONS] onOrdersRowClicked`, order);
      this.dialog.order.data = order;
      this.dialog.order.show = true;
    },
    onSearchOrdersClick() {
      this.orders.search = '';
      this.dialog.search.show = true;
    },
    clearSearchOrders() {
      this.orders.search = '';
      this.fetchOrders();
    },
    onTimeRangeClick() {
      this.dialog.timeRange.show = true;
    },  
    forceOrdersRefresh() {
      this.orders.refreshLoading = true;
      this.$store.commit('setStoresDataUpdateStatus', 'dirty');//WARNING: This force update of stores data next time it's required.
      this.fetchOrders();
      this.fetchStoreInfo();//NOTE: Force cached store data to be refreshed.
    },

    cancelOrder() {
      // Check whether the current user is authorized to cancel order.
      if (!this.authCanCancelPointTransaction) {
        this.$notify({
          type: 'error',
          text: '您沒有取消訂單的權限！如有需要請聯繫您的特店管理員協助。'
        });
        return;
      }

      console.log(`[TRANSACTIONS] cancelOrder`, this.dialog.order.data);
      this.$swal({
        background: '#424242',
        color: '#ffffff',
        html: '<span style="color:white">確定要取消此訂單嗎？支付的點數將會退還、款項將會發起退款。</span>',
        icon: 'warning',
        confirmButtonText: '確定',
        showCloseButton: true
      })
      .then(result => {
        if (!result.isConfirmed) return;

        let orderId = this.dialog.order.data.orderId;
        let storeName = this.dialog.order.data.storeName;
        SSORequest.post(
          `${process.env.VUE_APP_TY_PAYMENT_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
          + `/orders/${orderId}/cancel`,
          { note: `特店管理後台退點：${storeName}`, cancelledBy: this.user.displayName }
        )
        .then(response => {
          this.forceOrdersRefresh();
          this.$notify({
            type: 'success', text: `已請求取消訂單。`
          });
        })
        .catch(err => {
          console.error(`[TRANSACTIONS] cancelOrder: error`, err);
          this.$notify({
            type: 'error', text: `無法取消訂單！`
          });
        })
        .finally(() => {
          this.dialog.order.show = false;
        });
      });
    },

    cancelPointTransaction() { //WARNING: Deprecated therefore unused.
      console.log(`[TRANSACTIONS] cancelPointTransaction`, this.dialog.order.data);
      this.$swal({
        background: '#424242',
        color: '#ffffff',
        html: '<span style="color:white">確定要退還此交易的點數嗎？</span>',
        icon: 'warning',
        confirmButtonText: '確認退點',
        showCloseButton: true
      })
      .then(result => {
        if (!result.isConfirmed) return;
        let merchantOrderId = this.dialog.order.data.merchantOrderId;
        let receiverEntityName = this.dialog.order.data.receiverEntityName;
        SSORequest.post(
          `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}/stores/${this.storeId}`
          + `/orders/${merchantOrderId}/cancel`,
          { note: `特店管理後台退點：${receiverEntityName}`, cancelledBy: this.user.displayName }
        )
        .then(response => {
          // this.$store.commit('setStoresDataUpdateStatus', 'pending');//WARNING: This force update of stores data next time it's required.
          // this.fetchOrders();
          // this.fetchStoreInfo();//NOTE: Force cached store data to be refreshed.
          this.forceOrdersRefresh();
          this.$notify({
            type: 'success', text: `已請求退回點數 ${this.dialog.order.data.pointAmount} 點`
          });
        })
        .catch(err => {
          console.error(`[TRANSACTIONS] cancelPointTransaction: error`, err);
          this.$notify({
            type: 'error', text: `無法退回點數！`
          });
        })
        .finally(() => {
          this.dialog.order.show = false;
        });
      });
    
    },

    /// Utilities for list and order detail
    getPaymentIcon(service, type) {
      return {
        'tappay-apple': require('@/assets/icons/apple.svg'),
        'tappay-google': require('@/assets/icons/google.svg'),
        'tappay-direct': require('@/assets/icons/credit.svg'),
        'tappay-credit': require('@/assets/icons/credit.svg'),
        'linepay-linepay': require('@/assets/icons/line.svg'),
        'easywallet-easywallet': require('@/assets/icons/easy.svg'),
        'tappay-jko': require('@/assets/icons/jko.svg'),
        'taiwanpay-qrcode': require('@/assets/icons/taiwan.svg'),
      }[ `${service}-${type}` ];
    },
    getPaymentMethodLiteral(service, type) {
      return {
        'tappay-apple': 'Apple Pay',
        'tappay-google': 'Google Pay',
        'tappay-direct': '信用卡',
        'tappay-credit': '信用卡',
        'linepay-linepay': 'LINE Pay',
        'easywallet-easywallet': '悠遊付',
        'tappay-jko': '街口支付',
        'taiwanpay-qrcode': '台灣Pay',
      }[ `${service}-${type}` ];
    },
    getOrderStatusLiteral(order) {
      if (order.paymentMethod === 'online') {
        return {
          'created': '已請求',
          'paid': '已付款',
          'captured': '已請款',
          'cancelled': '已取消',
          'refunded': '已退款'
        }[ order.paymentStatus ];
      }
      else {
        return {
          'created': '點數已請求',
          'captured': '點數已請款',
          'cancelled': '點數已退還',
        }[ order.pointOrderStatus ];
      }
    },
    getTotalPointsByPointCode(_pointCode) {
      if (!this.orders.data)
        return 0;
      if (!this.orders.data.points)
        return this.orders.data['totalConfirmedAmount'];
      let thePoint = this.orders.data.points.find(p => p.pointCode === _pointCode);
      return thePoint?.totalCapturedPoints || 0;
    }

  },

  data() {
    return {
      tab: 'orders',
      points: [ ],

      // Data table data
      orders: {
        rows: [ ],
        cols: [
          { text: '', value: 'psIcon' },
          { text: '交易', value: 'left' },
          { text: '點數', value: 'right', align: 'right' }
        ],
        page: 1,
        pageCount: 0,
        search: '',
        sort: 'createdTs:desc',
        transactionType: null,
        transactionTypeOptions: [
          { text: '所有', value: 'all' },
          { text: '消費折抵', value: 'Consumption-Discount' },
          { text: '預算分配', value: 'Budget-Allocation' }
        ],
        data: { },

        dates: [
          moment().add(-7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')
        ],
        dateRangePickerShortcuts: [
          { text: '今天', dates: [ moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ] },
          { text: '昨天', dates: [ moment().add(-1, 'days').format('YYYY-MM-DD'), moment().add(-1, 'days').format('YYYY-MM-DD') ] },
          { text: '本週', dates: [ moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD') ] },
          {
            text: '上週', dates: [
              moment().add(-1, 'weeks').startOf('week').format('YYYY-MM-DD'),
              moment().add(-1, 'weeks').endOf('week').format('YYYY-MM-DD')
            ]
          },
        ],

        refreshLoading: false
      },

      // Query parameters
      dates: [ ],

      dialog: {
        order: {
          show: false,
          data: { }
        },
        search: {
          show: false
        },
        timeRange: {
          show: false,
          dates: [
            moment().startOf('day').toDate(),
            moment().endOf('day').toDate()
          ]
        }
      }
      
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    // store() {
    //   return this.$store.getters.store(this.storeId) || { };
    // },

    fetchOrdersUrl() {
      if (!this.storeId) return null;
      let fromTs = moment(this.orders.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      let toTs = moment(this.orders.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      // return `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
      //   + `/stores/${this.storeId}/orders?foo=bar`
      return `${process.env.VUE_APP_TY_PAYMENT_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/orders?foo=bar`
        + (this.orders.search? `&search=${this.orders.search}`: '')
        + `&from_ts=${fromTs}&to_ts=${toTs}`;//WARNING: Hard-coded from-to timestamps
    },

    // storeRoleId() {
    //   return this.$store.getters.storeRoleId(this.storeId);
    // },
    authCanCancelPointTransaction() {
      let storeRoleId = this.$store.getters.storeRoleId(this.storeId);
      return [ 1 , 2 ].includes(storeRoleId);
    }
  },

  filters: {
    toDateString(t) {
      return moment(t, 'YYYY-MM-DD').format('YYYY/M/D');
    },
    tsToDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D hh:mmA');
    },
    tsToDateStringMore(ts) {
      return moment.unix(ts).format('YYYY/M/D h:mm:ssA');
    },
    toTypeLiteral(t) {
      return {
        'Budget-Allocation': '預算分配',
        'Consumption-Discount': '消費折抵'
      }[ t ] || '';
    },
    toOrderStatusLiteral(t) {
      return {
        '0': '已完成',
        '1': '已取消',
        'confirmed': '已完成',
        'cancelled': '已取消'
      }[ t ] || '';
    },
    toSettlementStatusLiteral(t) {
      return {
        'open': '未結算',
        'closed': '結算中',
        'settled': '已結算'
      }[ t ] || '';
    },

    // toApprovalStatusLiteral(t) {
    //   return {
    //     'pending': '審核中',
    //     'approved': '已完成',
    //     'rejected': '審核失敗',
    //     'insufficient': '待補件'
    //   }[ t ] || '';
    // },

    toGrantStatusLiteral(t) {
      let [ approvalStatus, grantStatus ] = t.split('-');
      if (approvalStatus != 'approved') {
        return {
          'pending': '審核中',
          'approved': '已完成',
          'rejected': '審核失敗',
          'insufficient': '資料不齊全、待補件'
        }[ approvalStatus ] || '';
      }
      else {
        return {
          'processing': '撥款進行中',
          'granted': '撥款成功'
        }[ grantStatus ] || '';
      }
    }
  }
}
</script>

<style scoped>
div:not(.v-dialog) > .theme--dark.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}

.theme--dark.v-data-table {
  background-color: transparent;
}

.lightgreen--text {
  color:#00E676;
}
</style>