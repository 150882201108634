import SSORequest from '@/sso-request';

export default {

  created() {
    this.fetchStoreCategories();
  },

  methods: {
    fetchStoreCategories() {
      SSORequest.get(`${process.env.VUE_APP_TY_MANAGER_HOST}/store-categories`)
      .then(response => {

        const categories = response.data;
        categories.forEach(ctg => {
          this.storeCategoryOptions.push({
            text: ctg.name,
            value: ctg.id
          });
        });
      })
      .catch(err => {
        console.error(`[MIXIN-STORE-CATEGORIES] fetchBankCode: error`, err);
      });
    }
  },

  data: () => {
    return {

      storeCategoryOptions: [
        // { text: '餐飲業', value: 1 },
        // { text: '旅宿業', value: 2 },
      ]
    };
  }
}