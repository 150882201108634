<template>
  <qrcode-stream :camera="camera" :track="paintOutline" style="height:270px"
    @init="onInit" @detect="onDetected">

    <!-- Erroneous -->
    <div style="width:100%;height:100%;background:rgba(255,255,255,0.1);" class="d-flex justify-center align-center"
      v-if="error">
      <span class="text-h5">{{ error }}</span>
    </div>

    <!-- Loading -->
    <div style="width:100%;height:100%;" class="d-flex justify-center align-center"
      v-else-if="isLoading">
      <v-progress-circular indeterminate color="red" size="64"></v-progress-circular>
    </div>

    <!-- Crosshair -->
    <div style="width:100%;height:100%;" class="d-flex justify-center align-center"
      v-else-if="camera == 'auto'">
      <v-img :src="require('@/assets/img/crosshair2.png')" max-width="200"></v-img>
    </div>

    <!-- Non-active -->
    <div style="width:100%;height:100%;background:rgba(255,255,255,0.1);" class="d-flex justify-center align-center"
      v-else>
      <span class="text-h5">{{ placeholderText }}</span>
    </div>

  </qrcode-stream>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: {
    QrcodeStream
  },
  props: {
    placeholderText: String
  },

  mounted() {
    // this.start();
  },
 
  methods: {

    start() {
      this.camera = 'auto';
    },
    pause() {
      this.camera = 'off';
    },

    onInit(promise) {
      console.warn(`[QRCODE-READER] onInit`);
      // console.log(`[QRCODE-READER]<DEBUG> onInit: window`, window.innerWidth);
      this.isLoading = true;
      promise.then(result => {
        // if (result.capabilities && result.capabilities.deviceId)
        //   this.isLoading = false;
      })
      .catch(err => {
        this.error = err.name;
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    onDetected(promise) {
      // console.warn(`[QRCODE-READER] onDetected`, raw);

      promise.then(result => {
        console.warn(`[QRCODE-READER] onDetected result`, result);
        let { content, location } = result;
        this.raw = content;
        this.$emit('detect', this.raw);
      })
      .catch(err => {
        console.error(`[QRCODE-READER] onDetected failed`, err);
      });
    },

    // QRCode Stream track function
    paintOutline(detectedCodes, ctx) {
      if (!this.canvas) {
        this.canvas = ctx.canvas;
        console.log(`[QRCODE-READER]<DEBUG> paintOutline: canvas dimensions`, this.canvas.width, this.canvas.height);
      }

      for (const code of detectedCodes) {
        
        // // Draw coordinates.
        // const { boundingBox, rawValue } = code;
        // const centerX = boundingBox.x + boundingBox.width/ 2
        // const centerY = boundingBox.y + boundingBox.height/ 2

        // const fontSize = Math.max(12, 50 * boundingBox.width/ctx.canvas.width)
        // // console.log(boundingBox.width, ctx.canvas.width)

        // ctx.font = `bold ${fontSize}px sans-serif`
        // ctx.textAlign = "center"

        // let text = `(${boundingBox.x}, ${boundingBox.y})`
        // ctx.lineWidth = 3
        // ctx.strokeStyle = '#35495e'
        // ctx.strokeText(text, centerX, centerY)

        // ctx.fillStyle = '#5cb984'
        // ctx.fillText(text, centerX, centerY)


        // Draw outline
        const [ firstPoint, ...otherPoints ] = code.cornerPoints;
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 4;

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints)
          ctx.lineTo(x, y);
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    }

  },

  data() {
    return {
      canvas: null,
      camera: 'off',
      isLoading: false,
      raw: null,
      error: null
    };
  }
}
</script>

<style scoped>
.qrcode-stream-overlay {
  height: 200px !important;
}
</style>