<template>
  <v-container>

    <!-- HEADER for stamp card info. -->
    <v-row>

      <v-col cols="8">
        <v-list-item three-line>
          <v-list-item-content>
            <!-- <div class="text-overline mb-4">
              OVERLINE
            </div> -->
            <v-list-item-title class="text-h6 mb-2">
              {{ stampCard.stampCardName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              集章 {{ stampCard.collectStartTime | toShortDateString }} - {{ stampCard.collectEndTime | toShortDateString }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              兌換 {{ stampCard.redeemStartTime | toShortDateString }} - {{ stampCard.redeemEndTime | toShortDateString }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="4" class="my-2 d-flex justify-center align-center">
        <v-img max-width="84" max-height="84" class="rounded-lg"
          :src="stampCard.stampImageUrl">
        </v-img>
      </v-col>

    </v-row>

    <!-- TAB of Transaction Types -->
    <v-btn-toggle rounded color="primary" class="mb-2 d-flex flex-row" v-model="tab" mandatory>
      <v-btn x-large class="flex-grow-1" value="stamps">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-black">發章記錄</span>
        </div>
      </v-btn>
      <v-btn x-large class="flex-grow-1" value="rewards">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-black">兌換記錄</span>
        </div>
      </v-btn>
    </v-btn-toggle>

    <!-- Table of collect-stamps transactions -->
    <template v-if="tab == 'stamps'">
      <SSRTable ref="list-collect-stamps" :hideHeaders="true"
        :data-url="fetchTransactionsCollectStampsUrl" :data-key="'transactions'" :cols="stamps.cols"
        @click-row="onCollectStampsRowClicked">

        <div slot="left" slot-scope="{ item }">
          <span class="text-subtitle-2">現場發章</span><br>
          <span class="grey--text">{{ item.phone }}</span>
        </div>

        <div slot="right" slot-scope="{ item }">

          <div class="d-flex flex-column">
            <div class="d-flex justify-end align-center">
              <!-- <CustomIcon :src="stampCard.stampImageUrl" :width="24" :height="24" class="rounded"></CustomIcon> -->
              <span class="ml-2 text-h6" style="color:#27AE60">+{{ item.amount }}</span>
            </div>
            <span>{{ item.createdAt | tsToDateString }}</span>
          </div>
        </div>
      </SSRTable>
    </template>

    <!-- Table of collect-stamps transactions -->
    <div v-if="tab == 'rewards'">
      <SSRTable ref="list-redeem-rewards" :hideHeaders="true"
        :data-url="fetchTransactionsRedeemRewardsUrl" :data-key="'transactions'" :cols="rewards.cols"
        @click-row="onCollectStampsRowClicked">

        <div slot="left" slot-scope="{ item }">
          <span class="text-subtitle-2">獎品兌換 - {{ item.rewardName }}</span><br>
          <span class="grey--text">{{ item.phone }}</span>
        </div>

        <div slot="right" slot-scope="{ item }">
          <div class="d-flex flex-column">
            <!-- <div class="d-flex justify-end align-center">
              <CustomIcon :src="item.rewardImageUrl" :width="24" :height="24" class="rounded"></CustomIcon>
              <span class="ml-2 text-h6" style="color:#27AE60">+{{ item.amount }}</span>
            </div> -->
            <span>{{ item.createdAt | tsToDateString }}</span>
          </div>
        </div>
      </SSRTable>
    </div>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable, DateRangePicker
  },
  props: {
    storeId: String,
    stampCardId: String
  },

  mounted() {
    this.fetchStampCardInfo();
  },

  methods: {

    fetchStampCardInfo() {
      return SSORequest.get(`${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}`)
      .then(response => {
        this.stampCard = response.data;
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得集章卡資訊！'
        });
      });
    },

    onCollectStampsRowClicked(transaction) {

    }
  },

  data() {
    return {
      tab: 'stamps',

      stampCard: { },

      stamps: {
        cols: [
          { text: '', value: 'left' },
          { text: '', value: 'right', align: 'end' },
        ]
      },

      rewards: {
        cols: [
          { text: '', value: 'left' },
          { text: '', value: 'right' },
        ]
      }
      
    }
  },

  computed: {
    fetchTransactionsCollectStampsUrl() {
      if (!this.storeId) return null;
      // let fromTs = moment(this.orders.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      // let toTs = moment(this.orders.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/transactions?foo=bar&transactionTypes=collect-stamps`
        + `&stampCardId=${this.stampCardId}`
        + `&from=20220101&to=20241231`;
        // + `&from_ts=${fromTs}&to_ts=${toTs}`;//WARNING: Hard-coded from-to timestamps
    },

    fetchTransactionsRedeemRewardsUrl() {
      if (!this.storeId) return null;
      // let fromTs = moment(this.orders.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      // let toTs = moment(this.orders.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/transactions?foo=bar&transactionTypes=redeem-reward`
        + `&stampCardId=${this.stampCardId}`
        + `&from=20220101&to=20241231`;
        // + `&from_ts=${fromTs}&to_ts=${toTs}`;//WARNING: Hard-coded from-to timestamps
    },
  },

  watch: {
    tab(v) {  console.log('tab changed', v); }
  },

  filters: {
    tsToDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D hh:mmA');
    },
    toShortDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D');
    }
  }
}
</script>