<template>
  <div ref="map-container" :style="`height: ${height}px`"
    class="rounded-xl">
    <div ref="map-autocomplete" class="ma-2 map-autocomplete d-flex justify-center">
      <v-text-field light dense filled outlined rounded hide-details
        placeholder="搜尋商店名稱">
      </v-text-field>
    </div>
  </div>  
</template>

<script>

import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'GoogleMapPlaceSearchWidget',
  props: {
    height: {
      type: Number,
      default: 240
    },
    initPlaceId: String
  },

  mounted() {

    this.initMap();
  },

  methods: {

    async initMap() {

      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: [ 'places' ]
      });

      this.google = await loader.load();
      
      // Init map itself.
      this.map = new this.google.maps.Map(
        this.$refs['map-container'], {
          zoom: 17,
          center: { lat: 25.048, lng: 121.517 },
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false
        }
      );

      // Instantiate autocomplete search input.
      const el_autocomplete = this.$refs['map-autocomplete'];
      const el_input = el_autocomplete.getElementsByTagName('input')[0];
      this.map.controls[ this.google.maps.ControlPosition.TOP_RIGHT ].push(el_autocomplete);
      this.autocomplete = new this.google.maps.places.Autocomplete(el_input, {
        componentRestrictions: { country: 'tw' }
      });
      this.autocomplete.setFields(['address_components', 'geometry', 'name', 'place_id']);

      // Init a marker to work with autocomplete's search result.
      this.marker = new this.google.maps.Marker({ map: this.map });
      this.marker.setVisible(false);

      //TODO: If an init-place-ID is given, we will put the marker there and center it.
      if (this.initPlaceId) {
        let placeService = new this.google.maps.places.PlacesService(this.map);
        placeService.getDetails({ placeId: this.initPlaceId }, (place, status) => {
          if (status != this.google.maps.places.PlacesServiceStatus.OK)
            return;
          // Center the map to the selected place.
          this.map.setCenter(place.geometry.location);
          // Put the marker.
          this.marker.setPosition(place.geometry.location);
          this.marker.setVisible(true);
        });
      }

      // Listen to autocomplete event.
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry) {
          //TODO: Alert about the incorrectly entered input.
          return;
        }
        console.log(`[GOOGLE-MAP-PLACE-SEARCH-WIDGET]<DEBUG> initMap: new place selected`, place);
        // Center the map to the selected place.
        this.map.setCenter(place.geometry.location);
        // Put the marker.
        this.marker.setPosition(place.geometry.location);
        this.marker.setVisible(true);

        // Emit the place-change event.
        this.$emit('change', place);
      });
    },

    updatePlace() {
      let placeService = new this.google.maps.places.PlacesService(this.map);
      placeService.getDetails({ placeId: this.initPlaceId }, (place, status) => {
        if (status != this.google.maps.places.PlacesServiceStatus.OK)
          return;
        // Center the map to the selected place.
        this.map.setCenter(place.geometry.location);
        // Put the marker.
        this.marker.setPosition(place.geometry.location);
        this.marker.setVisible(true);
      });
    }
  },

  data() {
    return {
      google: null, //Access to Google Maps Javascript API SDK
      map: null,
      autocomplete: null,
      marker: null
    };
  },

  watch: {
    initPlaceId(placeId) {
      this.updatePlace();
    }
  }
}
</script>

<style scoped>
.map-autocomplete {
  width: 75%;
}
.map-autocomplete .v-input {
  background-color: rgba(255, 255, 255, 1);
}
</style>