<template>
  <!-- <div> -->
    <router-view/>
  <!-- </div> -->
</template>

<script>

import EventBus from './event-bus';

export default {

  // created() {
  //   this.$store.dispatch('signIn')
  //   .then(() => {
  //     this.initApp();
  //     // this.$router.push('/admin');
  //   })
  //   .catch(err => {
  //     console.error(`[APP] Failed to login`, err);
  //   });
  // },

  methods: {

    initApp() {
      // Fetch stores of the current user (member).
      this.$store.dispatch('initStoresData')
      .then(storeMap => {
        EventBus.$emit('init-stores-data-ready');
      });
    }

  }
}
</script>