import SSORequest from '@/sso-request';
// import axios from 'axios';

export default {

  created() {
    this.fetchBankCodes();
  },

  methods: {
    fetchBankCodes() {
      SSORequest.get(`${process.env.VUE_APP_TY_MANAGER_HOST}/bank-codes`)
      .then(response => {
        let codes = response.data;
        codes.forEach(code => {

          // Push a bank
          if (code['branch_code'] == null) {
            this.bankOptions.push({
              text: `${code['bank_code']} ${code['name']}`,
              value: code['bank_code']
            });
          }
          // Push a branch
          else {
            this.branchOptions.push({
              bankCode: code['bank_code'],
              text: `${code['branch_code']} ${code['name']}`,
              value: code['branch_code']
            });
          }
            
        });
      })
      .catch(err => {
        console.error(`[MIXIN-BANK-CODES] fetchBankCode: error`, err);
      })
    }
  },

  data: () => {
    return {
      bankOptions: [ ],
      branchOptions: [ ]
    };
  }
};