import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueI18n from 'vue-i18n'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import Vuelidate from 'vuelidate'

import 'sweetalert2/dist/sweetalert2.min.css'

import router from './router'
import store from './store'
import dictionary from './dictionary'
import './registerServiceWorker'

Vue.config.productionTip = false

// Vue.prototype.$qcsso = window.qcsso;
Vue.use(VueI18n)
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#EF6079'
  // cancelButtonColor: '#ff7674',
})
Vue.use(VueNotification)
Vue.use(Vuelidate)

// Register some components globally.
Vue.component('CustomIcon', () => import('@/components/CustomIcon.vue'))

// I18n settings
const i18n = new VueI18n({
  locale: 'tw',
  messages: dictionary, // set locale messages
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
