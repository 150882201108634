<template>
  <v-container>

    <!-- List of stamp cards -->
    <v-card outlined rounded="xl" class="mx-auto px-4 mb-3"
      v-for="sc in stampCards" :key="sc.stampCardId">

      <v-row align="center" class="pt-2">

        <v-col cols="8">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 mb-2">
                {{ sc.stampCardName }}
              </v-list-item-title>
              <v-list-item-subtitle :class="{ 'red--text': sc.collectEndTime < nowTs }">
                集章 {{ sc.collectStartTime | toShortDateString }} - {{ sc.collectEndTime | toShortDateString }}
              </v-list-item-subtitle>
              <v-list-item-subtitle :class="{ 'red--text': sc.redeemEndTime < nowTs }">
                兌換 {{ sc.redeemStartTime | toShortDateString }} - {{ sc.redeemEndTime | toShortDateString }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="4" class="my-2 d-flex justify-center align-center">
          <v-img max-width="72" max-height="72" class="rounded-lg"
            :src="sc.stampImageUrl">
          </v-img>
        </v-col>

        <!-- Action buttons -->
        <v-col cols="12" class="pt-0 d-flex">
          <v-btn text class="flex-grow-1"
            @click="$router.push('/admin/stores/' + storeId + '/stamp-cards/' + sc.stampCardId + '/info')">
            集章資訊
          </v-btn>
          <v-divider vertical></v-divider>
          <v-btn text class="flex-grow-1"
            @click="$router.push('/admin/stores/' + storeId + '/stamp-cards/' + sc.stampCardId + '/transactions')">
            交易記錄
          </v-btn> 
        </v-col>

      </v-row>

    </v-card>

    <!-- Footer - Button for applying withdrawal -->
    <v-footer fixed style="background:transparent;">
      <v-btn large block rounded color="primary"
        @click="$router.push(`/admin/stores/${storeId}/create-stamp-card`)">
        <v-icon>mdi-plus</v-icon>
        &nbsp;新增集章卡
      </v-btn>
    </v-footer>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable
  },
  props: {
    storeId: String
  },

  mounted() {
    this.fetchStampCards();
  },

  methods: {

    fetchStampCards() {
      return SSORequest.get(`${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/stamp-cards`)
      .then(response => {
        this.stampCards = response.data.stampCards;
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得集章卡列表！'
        });
      });
    },

    goToStampCardInfo() {

    }
  },

  data() {
    return {
      stampCards: [ ]
    };
  },

  computed: {
    nowTs() {
      return moment().unix();
    }
  },

  filters: {
    toShortDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D');
    }
  }
}
</script>

<style scoped>
.theme--dark.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>