var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 mb-2"},[_vm._v(" "+_vm._s(_vm.stampCard.stampCardName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" 集章 "+_vm._s(_vm._f("toShortDateString")(_vm.stampCard.collectStartTime))+" - "+_vm._s(_vm._f("toShortDateString")(_vm.stampCard.collectEndTime))+" ")]),_c('v-list-item-subtitle',[_vm._v(" 兌換 "+_vm._s(_vm._f("toShortDateString")(_vm.stampCard.redeemStartTime))+" - "+_vm._s(_vm._f("toShortDateString")(_vm.stampCard.redeemEndTime))+" ")])],1)],1)],1),_c('v-col',{staticClass:"my-2 d-flex justify-center align-center",attrs:{"cols":"4"}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"84","max-height":"84","src":_vm.stampCard.stampImageUrl}})],1)],1),_c('v-btn-toggle',{staticClass:"mb-2 d-flex flex-row",attrs:{"rounded":"","color":"primary","mandatory":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-btn',{staticClass:"flex-grow-1",attrs:{"x-large":"","value":"stamps"}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-subtitle-1 font-weight-black"},[_vm._v("發章記錄")])])]),_c('v-btn',{staticClass:"flex-grow-1",attrs:{"x-large":"","value":"rewards"}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-subtitle-1 font-weight-black"},[_vm._v("兌換記錄")])])])],1),(_vm.tab == 'stamps')?[_c('SSRTable',{ref:"list-collect-stamps",attrs:{"hideHeaders":true,"data-url":_vm.fetchTransactionsCollectStampsUrl,"data-key":'transactions',"cols":_vm.stamps.cols},on:{"click-row":_vm.onCollectStampsRowClicked},scopedSlots:_vm._u([{key:"left",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("現場發章")]),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.phone))])])}},{key:"right",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('span',{staticClass:"ml-2 text-h6",staticStyle:{"color":"#27AE60"}},[_vm._v("+"+_vm._s(item.amount))])]),_c('span',[_vm._v(_vm._s(_vm._f("tsToDateString")(item.createdAt)))])])])}}],null,false,1011815628)})]:_vm._e(),(_vm.tab == 'rewards')?_c('div',[_c('SSRTable',{ref:"list-redeem-rewards",attrs:{"hideHeaders":true,"data-url":_vm.fetchTransactionsRedeemRewardsUrl,"data-key":'transactions',"cols":_vm.rewards.cols},on:{"click-row":_vm.onCollectStampsRowClicked},scopedSlots:_vm._u([{key:"left",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("獎品兌換 - "+_vm._s(item.rewardName))]),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.phone))])])}},{key:"right",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm._f("tsToDateString")(item.createdAt)))])])])}}],null,false,2930564254)})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }