  <template>
  <v-container>

    <!-- Overall Points Info -->
    <!-- <v-skeleton-loader class="mx-auto mb-4" type="card" height="136px"
      v-if="loadingSummaries">
    </v-skeleton-loader> -->
    <!-- <v-card outlined rounded="xl" class="mx-auto px-4 py-2 mb-4"
      v-for="point in pointSummaries" :key="point.pointCode">
      
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-1">{{ point.pointName }}</v-list-item-title>
          <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex align-center">
            <v-chip outlined color="#EF6079"
              class="ma-2 white--text text-h6">
              {{ point.pointBalanceTotal }}
            </v-chip>
            <span class="text-subtitle-2">合計可提領</span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="80">
          <CustomIcon :src="require('@/assets/icons/point.svg')"></CustomIcon>
        </v-list-item-avatar>

      </v-list-item>
    </v-card> -->

    <!-- Header line -->
    <div class="mb-4 d-flex justify-space-between align-center"
      v-for="point in pointSummaries" :key="point.pointName">
      <span class="ml-2 text-h6 font-weight-black primary--text">{{ point.pointName }}</span>

      <div class="mr-4 d-flex align-center">
        <CustomIcon :src="getPointsIconSrc()" :width="36"></CustomIcon>
        <span class="text-h6">&nbsp;{{ Number(point.pointBalanceTotal).toLocaleString() }}</span>
      </div>
    </div>

    <!-- Search store input -->
    <v-text-field dense filled rounded hide-details class="mb-3" clearable
      label="搜尋" prepend-inner-icon="mdi-magnify"
      v-model="searchKeyword">
    </v-text-field>

    <!-- List of Stores (as cards) -->

    <v-card outlined rounded="xl" class="mx-auto px-4 pb-1 mb-3"
      v-for="store in stores" :key="store.id" >

      <v-row>

        <v-col cols="9">
          <v-card-title class="text-h6 mb-1">{{ store.name }}</v-card-title>
          <v-card-subtitle class="text-caption">
            {{ store.role_id | toRoleLiteral }}
            <!-- <span v-if="store.approve_status == 'reviewing'">
              待審核
            </span>
            <span v-else-if="true || store.approve_status == 'insufficient'">
              需要補件
            </span>
            <span v-else-if="store.approve_status == 'rejected'">
              審核未過
            </span> -->
          </v-card-subtitle>


          <v-card-text class="pb-1 d-flex align-center white--text text-h6">
            <template v-if="store.approve_status == 'approved'">
              <div class="d-flex flex-column">
                <span class="text-subtitle-2 font-weight-bold">可提領 {{ Number(store.closingBalance).toLocaleString() }}</span>
                <div class="d-flex">
                  <CustomIcon :src="require(`@/assets/icons/ttcoin.svg`)" :width="32"></CustomIcon>
                  <span style="color: #EF6079;">&nbsp;{{ getStoreTyPointBalance(store) }}</span>
                </div>
              </div>
            </template>
            <template v-else-if="store.approve_status == 'reviewing'">
              <span style="color: #EF6079;">審核中</span>
            </template>
            <template v-else-if="store.approve_status == 'insufficient'">
              <span class="mr-2" style="color: #EF6079;">需要補件</span>
              <!-- <v-btn small rounded depressed color="primary"
                @click="$router.push('/admin/apply?storeId=' + store.id + '&reApplication=true')">
                申請補件
              </v-btn> -->
            </template>
            <template v-else-if="store.approve_status == 'rejected'">
              <span style="color: #EF6079;">審核未過</span>
            </template>
          </v-card-text>
        </v-col>

        <!-- <v-col cols="4" class="pt-4 pb-3 d-flex flex-wrap-reverse align-content-center"> -->
        <v-col cols="3" class="pt-4 pb-3 d-flex flex-column">
          <v-btn text class="flex-grow-1" :disabled="platform == 'ttpush'"
            v-if="store.approve_status == 'approved'"
            @click.stop="goToTransactions(store)">
            <div class="d-flex flex-column">
              <v-icon>mdi-view-list</v-icon>
              <span class="text-subtitle-2">交易記錄</span>
            </div>
          </v-btn>
          <v-divider v-if="store.approve_status == 'approved'"></v-divider>
          <!-- <v-btn text class="flex-grow-1" :disabled="store.approve_status != 'approved'"
            @click="goToShopInfo(store)">
            <div class="d-flex align-center">
              <CustomIcon :src="require('@/assets/icons/withdraw.svg')" :width="24"></CustomIcon>
              <span class="text-subtitle-2">申請提領</span>
            </div>
          </v-btn>
          <v-divider></v-divider> -->
          <v-btn text class="flex-grow-1" v-if="store.approve_status == 'approved'"
            @click="goToShopInfo(store)">
            <div class="d-flex flex-column">
              <v-icon>mdi-cog</v-icon>
              <span class="text-subtitle-2">特店管理</span>
            </div>
          </v-btn>

          <v-btn text class="flex-grow-1" v-if="store.approve_status == 'insufficient'"
            @click="$router.push('/admin/apply?storeId=' + store.id + '&reApplication=true')">
            <div class="d-flex flex-column">
              <v-icon>mdi-file-refresh</v-icon>
              <span class="text-subtitle-2">申請補件</span>
            </div>
          </v-btn>

          <!-- <v-btn outlined depressed color="rgba(255, 255, 255, 0.12)" class="px-2 my-1 rounded-lg">
            <template v-slot:default>
              <div class="store-action-btn">
                <v-icon>mdi-cog</v-icon>
                <span class="text-caption font-weight-bold">特店管理</span>
              </div>
            </template>
          </v-btn>
          <v-btn outlined depressed color="rgba(255, 255, 255, 0.12)" class="px-2 my-1 rounded-lg">
            <template v-slot:default>
              <div class="store-action-btn d-flex align-center">
                <CustomIcon :src="require('@/assets/icons/withdraw.svg')" :width="24"></CustomIcon>
                <span class="text-caption font-weight-bold">申請提領</span>
              </div>
            </template>
          </v-btn>
          <v-btn outlined depressed color="rgba(255, 255, 255, 0.12)" class="px-2 my-1 rounded-lg">
            <template v-slot:default>
              <div class="store-action-btn">
                <v-icon>mdi-view-list</v-icon>
                <span class="text-caption font-weight-bold">交易記錄</span>
              </div>
            </template>
          </v-btn>
           -->
          
        </v-col>

      </v-row>

    </v-card>

    <!-- A dummy spacing at the end of list FIXME: to avoid content overlapping -->
    <div style="height: 72px;">
    </div>


    <!-- <v-data-table mobile-breakpoint="200"
      hide-default-footer	@click:row="goToTransactions"
      :headers="cols" :items="stores">

      <template v-slot:[`header.name`]="{ header }">
        <span class="text-subtitle-1 font-weight-bold">{{ header.text }}</span>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="my-2">
          <span class="text-subtitle-1">{{ item.name }}</span><br>
          <span class="text-caption">{{ item.role_id | toRoleLiteral }}</span>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-btn icon color="blue-grey lighten-3" @click.stop="goToTransactions(item)">
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
          <v-btn icon color="blue-grey lighten-3" class="ml-2" @click.stop="goToShopInfo(item)">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </div>
      </template>

    </v-data-table> -->

  </v-container>
</template>

<script>

import PlatformParams from '@/mixins/platform-params';
import EventBus from '@/event-bus';

export default {

  mixins: [ PlatformParams ],

  mounted() {

    // this.$notify({
    //   group: 'noti-popup',
    //   title: '消費收點通知',
    //   text: ,
    // });

    this.$store.dispatch('initStoresData')
    .then(storeMap => {
      console.log(`[STORE-LIST] mounted: tried and obtained stores data mapping`, JSON.stringify(storeMap));
      this.loadingSummaries = false;
      this.updatePointSummaries();
    });

    EventBus.$on('init-stores-data-ready', () => {
      console.warn(`[STORE-LIST] mounted: got notified that stores data is ready.`);
      this.updatePointSummaries();
    });
  },

  beforeRouteLeave(to, from, next) {
    EventBus.$off('init-stores-data-ready');
    next();
  },

  methods: {

    updatePointSummaries() {
      
      let storeMap = this.$store.getters.storeMap;
      // console.log(`[STORE-LIST]<DEBUG> updatePointSummaries: triggered`, JSON.stringify(storeMap));
      let ret = { };
      for (let storeId in storeMap) {
        let store = storeMap[ storeId ];
        let points = store.points || [ ];//FIXME: Empty array to avoid undefined "store.points"
        points.forEach(point => {
          let pointCode = point['pointCode'];
          if (!ret[ pointCode ]) {
            ret[ pointCode ] = {
              pointCode, pointName: point.pointName,
              pointBalanceTotal: 0
            };
          }
          ret[ pointCode ].pointBalanceTotal += point['pointBalance'];
        });
      }
      this.pointSummaries = ret;
    },

    getStoreTyPointBalance(store) {
      if (!store.points)
        return 0;
      let temp = store.points.find(p => p.pointCode == 1);//WARNING: Hard-coded point-code.
      return (temp && temp.length)
        ? Number(temp.pointBalance).toLocaleString()
        : '0';
    },

    goToShopInfo(store) {
      this.$router.push(`/admin/stores/${store.id}/info`);
    },
    goToTransactions(store) {
      this.$router.push(`/admin/stores/${store.id}/transactions`);
    }
  },

  data() {
    return {
      loadingSummaries: true,
      pointSummaries: { },

      searchKeyword: '',

      // cols: [
      //   // { text: 'ID', value: 'id', sortable: false },
      //   { text: '特店', value: 'name', sortable: false },
      //   // { text: '權限', value: 'role', sortable: false },
      //   { text: '', value: 'actions', align: 'right', sortable: false },
      // ]
    };
  },

  computed: {
    stores() {
      // return this.$store.getters.stores;
      let stores = this.$store.getters.stores;
      if (!stores)
        return [ ];
      return stores
      .filter(store => {
        if (store['approve_status'] == 'rejected')
          return false;
        if (this.searchKeyword && this.searchKeyword.length)
          return store['name'].indexOf(this.searchKeyword) >= 0;
        return true;
      })
    },
    // storeMap() {
    //   // let temp = this.$store.getters.stores;
    //   // console.log(`[STORE-LIST] computed.storeMap triggered`, JSON.stringify(temp));
    //   // return temp;
    //   return this.$store.getters.storeMap;
    // },

    // pointSummaries() {
    //   // let storeMap = this.$store.getters.storeMap;
    //   let storeMap = this.storeMap;
    //   let ret = { };
    //   for (let storeId in this.storeMap) {
    //     let store = storeMap[ storeId ];
    //     let points = store.points;
    //     points.forEach(point => {
    //       let pointCode = point['pointCode'];
    //       if (!ret[ pointCode ]) {
    //         ret[ pointCode ] = {
    //           pointCode, pointName: point.pointName,
    //           pointBalanceTotal: 0
    //         };
    //       }
    //       ret[ pointCode ].pointBalanceTotal += point['pointBalance'];
    //     });
    //   }
    //   return ret;
    // },

    // pointsIconSrc() {
    //   // return '/assets/icons/point.svg';
    //   return require(`@${process.env.VUE_APP_ASSETS_POINTS_ICON}`);
    // }
    platform() {
      return process.env.VUE_APP_PLATFORM;
    }
  },

  watch: {
    'storeMap': 'updatePointSummaries'
  },

  filters: {
    toRoleLiteral(roleId) {
      return {
        1: '管理員',
        3: '財務人員',
        2: '操作人員'
      }[ roleId ] || '(unknown)';
    }
  }
}
</script>

<style scoped>
.theme--dark.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme--dark.v-data-table {
  background-color: transparent;
}

.store-action-btn {
  color: white;
}
</style>