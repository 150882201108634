export default {

  created() {
    
  },

  methods: {
    getMainLogoSrc() {
      return require(`@${process.env.VUE_APP_ASSETS_MAIN_LOGO}`);
    },

    getMunicipalTitle() {
      return process.env.VUE_APP_MUNICIPAL_TITLE;
    },

    getPointLogoSrc() {
      return require(`@${process.env.VUE_APP_POINT_LOGO_SKETCH || '/assets/logo.svg'}`);
    },

    shouldShowPointLogo() {
      return process.env.VUE_APP_POINT_LOGO_SKETCH.length !== '';
    },

    getPointsIconSrc() {
      // return '/assets/icons/point.svg';
      return require(`@${process.env.VUE_APP_ASSETS_POINTS_ICON}`);
    }
  },

  data: () => {
    return {

    };
  }
}