<template>
  <v-container>

    <validation-observer ref="observer" v-slot="{ invalid }">

      <v-form>
        <v-row>
          <v-col cols="12">
            <v-subheader>基本資訊</v-subheader>
            <v-divider></v-divider>  
          </v-col>
          <v-col cols="12">
            <span class="text-button">商店招牌名稱<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Name" rules="required|min:2">
              <v-text-field dense filled rounded required v-model="form.name"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">營業登記名稱<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Business Name" rules="required|min:2">
              <v-text-field dense filled rounded required v-model="form.businessName"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">商店類型<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Store Category" rules="required">
              <v-select dense filled rounded v-model="form.storeCategoryId"
                :items="storeCategoryOptions"
                :error-messages="errors">
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <span class="text-button">統一編號<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Ban ID" rules="required|digits:8">
              <v-text-field dense filled rounded required v-model="form.banId"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <span class="text-button">營業電話<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Phone" rules="required|min:9|max:10|numeric">
              <v-text-field dense filled rounded required v-model="form.phone"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">營業地址<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Address" rules="required|min:4">
              <v-text-field dense filled rounded required v-model="form.address"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">Google商家</span>
            <GoogleMapPlaceSearchWidget :init-place-id="form.placeId"
              @change="onPlaceSelected">
            </GoogleMapPlaceSearchWidget>
            <span class="text-caption grey--text">1. 在地圖上方輸入框中輸入您的商店名稱</span>
            <br>
            <span class="text-caption grey--text">2. 在出現的列表中點擊您的商店名稱</span>
          </v-col>

          <v-col cols="12">
            <v-subheader>聯絡資訊</v-subheader>
            <v-divider></v-divider>  
          </v-col>
          <v-col cols="6">
            <span class="text-button">負責人姓名<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Owner Name" rules="required|min:2">
              <v-text-field dense filled rounded required v-model="form.ownerName"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <span class="text-button">聯絡人姓名<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Contact Name" rules="required|min:2">
              <v-text-field dense filled rounded required v-model="form.contactName"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <span class="text-button">聯絡電話<sup>*</sup></span>
            <validation-provider v-slot="{ errors }" name="Contact Phone" rules="required|min:9|max:10|numeric">
              <v-text-field dense filled rounded required v-model="form.contactPhone"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">電子信箱</span>
            <validation-provider v-slot="{ errors }" name="Contact Email" rules="email">
              <v-text-field dense filled rounded required v-model="form.contactEmail"
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
          </v-col>
          
          <v-col cols="12">
            <v-subheader>
              帳務資訊
              <span v-if="!!currentStoreInfo.bankCode && !!currentStoreInfo.branchCode">
                （如欲更改銀行資料請洽客服）
              </span>
            </v-subheader>
            <v-divider></v-divider>  
          </v-col>
          <v-col cols="12">
            <span class="text-button">銀行代碼</span>
            <validation-provider v-slot="{ errors }" name="Bank Code">
              <v-select dense filled rounded v-model="form.bankCode"
                :items="bankOptions" :error-messages="errors" :disabled="!!currentStoreInfo.bankCode">
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">分行代碼</span>
            <validation-provider v-slot="{ errors }" name="Branch Code">
              <v-select dense filled rounded v-model="form.branchCode"
                :items="filteredBranchOptions" :error-messages="errors" :disabled="!!currentStoreInfo.branchCode"
                no-data-text="無對應的分行">
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">銀行帳號</span>
            <validation-provider v-slot="{ errors }" name="Account Number" rules="min:8|numeric">
              <v-text-field dense filled rounded required v-model="form.accountNumber"
                :error-messages="errors"
                :disabled="!!currentStoreInfo.accountNumber && currentStoreInfo.accountNumber.length > 0">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <span class="text-button">戶名</span>
            <validation-provider v-slot="{ errors }" name="Account Name" rules="min:2">
              <v-text-field dense filled rounded required v-model="form.accountName"
                :error-messages="errors"
                :disabled="!!currentStoreInfo.accountName && currentStoreInfo.accountName.length > 0">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <span class="text-button">身分證字號／統一編號</span>
            <validation-provider v-slot="{ errors }" name="Social ID / Business ID" rules="min:8|max:10">
              <v-text-field dense filled rounded required v-model="form.idNo"
                :error-messages="errors"
                :disabled="!!currentStoreInfo.idNo && currentStoreInfo.idNo.length > 0">
              </v-text-field>
            </validation-provider>
          </v-col>
          <!-- <v-col cols="12">
            <span class="text-button">上傳存摺封面</span>
            <validation-provider v-slot="{ errors }" name="Bank Book Image">
              <v-file-input dense rounded filled v-model="form.bankBookImage"
                prepend-icon=""  prepend-inner-icon="mdi-file-upload"
                show-size counter accept="image/png, image/jpeg"
                truncate-length="15" :error-messages="errors"
                :placeholder="!!form.previewBankBookImageUrl? '上傳新的圖片將取代原有的': ''"
              ></v-file-input>
            </validation-provider>
            
            <v-img :src="form.previewBankBookImageUrl" :height="180" v-if="form.previewBankBookImageUrl"
              class="rounded-xl" :class="{ 'blur-effect': !!form.bankBookImage }">
            </v-img>
          </v-col>
          <v-col cols="12">
            <span class="text-button">上傳統一編號章</span>
            <validation-provider v-slot="{ errors }" name="BAN Image">
              <v-file-input dense rounded filled v-model="form.banImage"
                prepend-icon=""  prepend-inner-icon="mdi-file-upload"
                show-size counter accept="image/png, image/jpeg"
                truncate-length="15"  :error-messages="errors"
                :placeholder="!!form.previewBanImageUrl? '上傳新的圖片將取代原有的': ''"
              ></v-file-input>
            </validation-provider>
            
            <v-img :src="form.previewBanImageUrl" :height="180" v-if="form.previewBanImageUrl"
              class="rounded-xl" :class="{ 'blur-effect': !!form.banImage }">
            </v-img>
          </v-col> -->

          <v-col cols="12" class="mt-2">
            <v-btn block large rounded color="primary"
              :loading="isProcessing" :disabled="isProcessing"
              @click="submit">
              <v-icon left>mdi-content-save</v-icon>
              儲存
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </validation-observer>

  </v-container>
</template>

<script>
import GoogleMapPlaceSearchWidget from '@/components/GoogleMapPlaceSearchWidget.vue';
import StoreCategories from '@/mixins/store-categories';
import BankCodes from '@/mixins/bank-codes';

// import axios from 'axios';
import SSORequest from '@/sso-request';
import { required, email, min, max, length, digits, numeric, regex } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: `此項目是必填的！`
});
extend('min', {
  ...min,
  message: `長度最少應為{length}字元`
});
extend('max', {
  ...max,
  message: `長度不得超過{length}字元`
});
extend('length', {
  ...length,
  message: `長度必須為{length}字元`
});
extend('digits', {
  ...digits,
  message: `必須為{length}碼數字`
});
extend('numeric', {
  ...numeric,
  message: `格式不正確`
});
extend('email', {
  ...email,
  message: `不合規的電子信箱格式`
});

export default {
  components: {
    GoogleMapPlaceSearchWidget,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [ StoreCategories, BankCodes ],
  props: {
    storeId: String
  },

  mounted() {

    // Fetch for current store info again.
    return SSORequest.get(`${process.env.VUE_APP_TY_STORES_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
      + `/stores/${this.storeId}/application`)//FIXME: 這其實跟補件取資訊的API一樣
    .then(response => {
      console.log(`[STORE-EDIT]<DEBUG> mounted: store info`, response.data);
      const data = response.data;
      this.currentStoreInfo = data;

      this.form.name = data['storeName'];
      this.form.businessName = data['businessName'];
      this.form.storeCategoryId = data['storeCategoryId'];
      this.form.banId = data['banId'];
      this.form.phone = data['phone'];
      this.form.address = data['address'];
      this.form.ownerName = data['ownerName'];
      this.form.contactName = data['contactName'];
      this.form.contactPhone = data['contactPhone'];
      this.form.contactEmail = data['contactEmail'];
      this.form.bankCode = data['bankCode'];
      this.form.branchCode = data['branchCode'];
      this.form.accountNumber = data['accountNumber'];
      this.form.accountName = data['accountName'];
      this.form.idNo = data['idNo'];
      this.form.geometry = JSON.parse(data['geometry']);//JSON in string
      this.form.placeId = data['storePlaceId'];

      // this.form.previewBankBookImageUrl = data['bankbookImageUrl'];
      // this.form.previewBanImageUrl = data['banImageUrl'];
    })
    .catch(err => {
      this.$notify({
        type: 'error', text: '無法取得特店資料！'
      });
    });

  },

  methods: {

    onPlaceSelected(place) {
      this.form.placeId = place['place_id'];
      this.form.placeName = place['name'];

      let geometry = place['geometry'];
      this.form.geometry = {
        location: {
          lat: geometry.location.lat(),
          lng: geometry.location.lng()
        },
        viewport: {
          south: geometry.viewport.getSouthWest().lat(),
          west: geometry.viewport.getSouthWest().lng(),
          north: geometry.viewport.getNorthEast().lat(),
          east: geometry.viewport.getNorthEast().lng()
        }
      };
      console.log(`[STORE-EDIT]<DEBUG> onPlaceSelected: geometry`, this.form.geometry);
    },

    submit() {
      console.warn(`[STORE-EDIT]<DEBUG> submit: will submit the application`, this.form);

      // Validate the form
      this.$refs['observer'].validate()
      .then(result => {
        if (!result) {
          this.$notify({
            type: 'error', text: '請檢查表單是否完整。'
          });
          return;
        }

        this.isProcessing = true;

        let formData = new FormData();
        // Mandatory stuff
        formData.append('merchantId', process.env.VUE_APP_MERCHANT_ID);
        formData.append('memberId', this.user.memberId);
        formData.append('name', this.form.name);
        formData.append('businessName', this.form.businessName);
        formData.append('phone', this.form.phone);
        formData.append('address', this.form.address);
        formData.append('storeCategoryId', this.form.storeCategoryId);
        formData.append('ownerName', this.form.ownerName);
        formData.append('contactName', this.form.contactName);
        formData.append('contactPhone', this.form.contactPhone);
        // formData.append('contactEmail', this.form.contactEmail);
        // Optional stuff
        if (this.form.contactEmail) formData.append('contactEmail', this.form.contactEmail);
        if (this.form.banId) formData.append('banId', this.form.banId);
        if (this.form.bankCode) formData.append('bankCode', this.form.bankCode);
        if (this.form.branchCode) formData.append('branchCode', this.form.branchCode);
        if (this.form.accountNumber) formData.append('accountNumber', this.form.accountNumber);
        if (this.form.accountName) formData.append('accountName', this.form.accountName);
        if (this.form.idNo) formData.append('idNo', this.form.idNo);
        // if (this.form.banImage) formData.append('banImage', this.form.banImage);
        // if (this.form.bankBookImage) formData.append('bankBookImage', this.form.bankBookImage);
        if (this.form.placeId) formData.append('storePlaceId', this.form.placeId);
        if (this.form.placeName) formData.append('googleStore', this.form.placeName);
        if (this.form.geometry) formData.append('geometry', JSON.stringify(this.form.geometry));

        formData.append('approveStatus', 'approved');//FIXME: 在已審核狀態下提交修改要加這個
        
        // Go for it.
        SSORequest({
          method: 'PUT',
          url: `${process.env.VUE_APP_TY_STORES_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}/stores/${this.storeId}`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        })
        .then(response => {
          this.$notify({
            type: 'success', text: '特店資訊更新已儲存。'
          });

          //WARNING: This force update of stores data next time it's required.
          this.$store.commit('setStoresDataUpdateStatus', 'dirty');

          //TODO: Redirect to main page?
          setTimeout(() => {
            this.$router.push('/admin/stores/' + this.storeId + '/info');
            this.isProcessing = false;
          }, 2000);
        })
        .catch(err => {
          this.$notify({
            type: 'error', text: '提交特店申請失敗！'
          });
          this.isProcessing = false;
        })
        // .finally(() => {
        //   this.isProcessing = false;
        // });
      });

      
    }

  },

  data() {
    return {
      isProcessing: false,

      currentStoreInfo: { },

      form: {
        banId: '', name: '', businessName: '', phone: '', address: '',
        ownerName: '', contactName: '', contactPhone: '', contactEmail: '',
        bankCode: null, branchCode: null, accountNumber: '', accountName: '', idNo: '',
        bankBookImage: null, banImage: null,
        storeCategoryId: 1,
        placeId: null,
        placeName: null,

        previewBankBookImageUrl: null,
        previewBanImageUrl: null,
      }
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    filteredBranchOptions() {
      if (!this.form.bankCode)
        return this.branchOptions;
      return this.branchOptions.filter(option => {
        // let code = option.value;
        // return code.indexOf(this.form.bankCode) === 0;
        return option.bankCode == this.form.bankCode;
      });
    },

    // reApplyingStore() {
    //   if (this.$route.query.storeId)
    //     return this.$store.getters.store(this.$route.query.storeId);
    //   return null;
    // }
  }

}
</script>

<style scoped>
.map-of-store-autocomplete {
  width: 100%;
}
.map-of-store-autocomplete .v-input {
  background-color: white;
}

form .col {
  padding-top: 0;
  padding-bottom: 0;
}

.blur-effect {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.text-shadow-blur-effect {
  text-shadow: 0 0 0.2em black;
}
</style>
