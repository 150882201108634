<template>
  <div>
    <v-menu ref="date-range-picker-menu"
      v-model="menu" :close-on-content-click="false"
      transition="scale-transition" left offset-y
      min-width="auto"
      :return-value.sync="dates">
      
      <template v-slot:activator="{on, attrs}">
        <v-text-field dense filled rounded v-on="on" :value="shownDateRangeText"
          prepend-inner-icon="mdi-calendar-outline" readonly hide-details>
        </v-text-field>
      </template>

      <v-date-picker range v-model="dates" @change="onDateRangeChanged">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="onConfirmed">
          OK
        </v-btn>
      </v-date-picker>

    </v-menu>

    <v-btn-toggle dense rounded color="primary" class="my-2">
      <v-btn v-for="s in shortcuts" :key="s.text" @click="onShortcutSelected(s)">
        {{ s.text }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateRangePicker',
  props: {
    // dates: {
    //   typeof: Array,
    //   default: () => [
    //     moment().format('YYYY-MM-DD'),
    //     moment().format('YYYY-MM-DD')
    //   ]
    // },
    value: Array,
    shortcuts: {
      typeof: Array,
      default: () => [ ]
    }
  },

  methods: {
    onDateRangeChanged() {
      console.warn(`[DATE-RANGE-PICKER]<DEBUG> onDateRangeChanged:`, this.dates);
    },
    onConfirmed() {
      this.shortcut = null;
      this.$refs['date-range-picker-menu'].save(this.dates);
      this.$emit('input', this.dates);
      this.menu = false;
    },

    onShortcutSelected(shortcut) {
      this.dates = shortcut.dates;
      this.$refs['date-range-picker-menu'].save(this.dates);
      this.$emit('input', this.dates);
    }
  },

  data() {
    return {
      menu: false,
      // dates: [
      //   moment().format('YYYY-MM-DD'),
      //   moment().format('YYYY-MM-DD')
      // ],
      dates: this.value,

      shortcut: null
    };
  },

  computed: {
    shownDateRangeText() {
      if (!this.dates || this.dates.length !== 2)
        return '';
      let from = moment(this.dates[0]).format('YYYY/M/D');
      let to = moment(this.dates[1]).format('YYYY/M/D');
      return `${from} - ${to}`;
    }
  },

  watch: {
    value(v) {
      this.dates = v;
    }
  }
}
</script>