<template>
  <v-container>

    <p class="px-2 text-body-2">確認提領點數及匯款帳戶正確，並按下送出申請，於審核通過後一個禮拜進行匯款。</p>

    <!-- Store Points Info -->
    <v-card outlined rounded="xl" class="mx-auto px-4 py-2">
      <v-list-item three-line>

        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-1">紅利桃子</v-list-item-title>
          <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex align-center">
            <v-chip outlined color="#EF6079" class="ma-2 white--text">
              可提領 {{ closingBalance }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="80">
          <CustomIcon :src="require('@/assets/icons/point.svg')"></CustomIcon>
        </v-list-item-avatar>

      </v-list-item>
    </v-card>

    <!-- Form: application for withdrawal -->
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12">
            <span class="text-button">特店</span>
            <v-select dense filled rounded hide-details
              prepend-inner-icon="mdi-store"
              v-model="$v.form.storeId.$model" :readonly="!!form.reApplicationId"
              :items="storesAsOptions" @change="fetchWithdrawalInfo">
            </v-select>
          </v-col>
          <!-- <v-col cols="12">
            <span class="text-button">銀行代碼</span>
            <v-text-field v-model="form.withdrawal.bankCode"
              dense filled rounded prepend-inner-icon="mdi-bank"
              required hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <span class="text-button">銀行帳號</span>
            <v-text-field v-model="form.withdrawal.accountNo"
              dense filled rounded prepend-inner-icon="mdi-checkbook"
              required hide-details>
            </v-text-field>
          </v-col> -->
          <v-col cols="12">
            <span class="text-button">收款帳號</span>
            <v-text-field dense filled rounded hide-details
              prepend-inner-icon="mdi-checkbook"
              required readonly
              :value="bankAccountText" >
            </v-text-field>
          </v-col>

          <!-- <v-col cols="12">
            <span class="text-button">上傳發票／收據證明</span>
            <v-file-input dense rounded filled v-model="$v.form.receiptImage.$model"
              prepend-icon=""  prepend-inner-icon="mdi-file-upload"
              show-size counter accept="image/png, image/jpeg"
              truncate-length="15"
            ></v-file-input>
          </v-col> -->

          <!-- <v-col cols="12">
            <span class="text-button">上傳統一編號章</span>
            <v-file-input dense rounded filled v-model="$v.form.banIdImage.$model"
              prepend-icon=""  prepend-inner-icon="mdi-file-upload"
              show-size counter accept="image/png, image/jpeg"
              truncate-length="15" 
            ></v-file-input>
          </v-col> -->

          <v-col cols="12" class="mt-2">
            <v-btn block large rounded color="primary"
              :loading="isProcessing" :disabled="isProcessing"
              @click="requestWithdrawal">
              送出{{ form.reApplicationId? '補件': '' }}申請
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

  </v-container>
</template>

<script>

import SSORequest from '@/sso-request';
// import axios from 'axios';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    storeId: String
  },

  mounted() {

    // If a store ID is given in query, apply it.
    if (this.$route.query.storeId) {
      this.form.storeId = this.$route.query.storeId;
    }
    else if (this.storeId) {
      this.form.storeId = this.storeId;
    }

    // A withdrawal ID could be given. In that case this is a re-application of withdrawal.
    if (this.$route.query.reApplication && !isNaN(this.$route.query.reApplication)) {
      this.form.reApplicationId = this.$route.query.reApplication;
    }

    if (this.form.storeId)
      this.fetchWithdrawalInfo();
  },

  methods: {

    fetchWithdrawalInfo() {
      let storeId = this.form.storeId;

      SSORequest.get(`${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}/stores/${storeId}/closing-balance`)
      .then(response => {
        this.closingBalance = response.data.closingBalance;
      })
      .catch(err => {
        this.$notify({
          type: 'error',
          text: '無法取得可提領點數資訊！'
        });
      });
    },

    requestWithdrawal() {
      console.log(`[WITHDRAW] requestWithdrawal: form model`, this.form);

      if (!this.form.reApplicationId && this.closingBalance <= 0) {
        this.$notify({
          type: 'error',
          text: '無可提領點數，無須申請。'
        });
        return;
      }

      this.$v.$touch();
      // console.log(`[WITHDRAW] requestWithdrawal: validator invalid`, this.$v.$invalid);
      // console.log(`[WITHDRAW] requestWithdrawal: validator storeId`, this.$v.form.storeId.required);
      // console.log(`[WITHDRAW] requestWithdrawal: validator receiptImage`, this.$v.form.receiptImage.required);
      // console.log(`[WITHDRAW] requestWithdrawal: validator banImage`, this.$v.form.banIdImage.required);
      if (this.$v.$invalid) {
        this.$notify({
          type: 'error',
          text: '缺少必要的項目。'
        });
        return;
      }

      this.isProcessing = true;

      // Construct form-data.
      let formData = new FormData();
      // formData.append('banImage', this.form.banIdImage);
      // formData.append('invoiceImage', this.form.receiptImage);
      formData.append('memberId', this.user.memberId);
      SSORequest({
        method: this.form.reApplicationId? 'PUT': 'POST',
        url: `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}/stores/${this.storeId}/withdraw`
          + `${this.form.reApplicationId? '/' + this.form.reApplicationId: ''}`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })
      .then(response => {
        console.log(`[WITHDRAW] requestWithdrawal: succeeded`, response.data);

        // Show successful alert.
        this.$swal({
          background: '#424242',
          color: '#ffffff',
          html: `<span style="color:white"。>提領${this.form.reApplicationId? '補件': ''}申請已送出！</span>`,
          icon: 'success',
          confirmButtonText: '確定',
          showCancelButton: true,
          cancelButtonText: '提領記錄'
        })
        .then(result => {
          if (!result.isConfirmed) {
            // This would open the Transactions page with withdrawal list opened.
            this.$router.push(`/admin/transactions?storeId=${this.storeId}&openTab=withdrawals`);
          }
        });

        // Refresh the closing balance.
        this.fetchWithdrawalInfo();
        this.$store.commit('setStoresDataUpdateStatus', 'dirty');//WARNING: This force update of stores data next time it's required.
      })
      .catch(err => {
        console.error(`[WITHDRAW] requestWithdrawal: failed`, err.response.data);
        this.$notify({
          type: 'error',
          text: '請求提領申請失敗！'
        });
      })
      .finally(() => {
        this.isProcessing = false;
      });
    }

  },
  
  data() {
    return {
      isProcessing: false,
      closingBalance: 0,

      form: {
        storeId: null,
        // receiptImage: null,
        // banIdImage: null,
        reApplicationId: null
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    storesAsOptions() {
      let stores = this.$store.getters.stores;
      return stores.map(store => {
        return {
          text: store['name'],
          value: `${store['id']}` 
        };
      });
    },

    selectedStore() {
      if (!this.form.storeId)
        return { };
      return this.$store.getters.store(this.form.storeId);
    },

    bankAccountText() {
      if (!this.form.storeId)
        return '';
      let selectedStore = this.$store.getters.store(this.form.storeId);
      if (!selectedStore || !selectedStore.bank_code)
        return '（無收款帳戶設定）';
      return `${selectedStore.bank_code} ${selectedStore.account_number} ${selectedStore.account_name}`;
    }
  },

  validations: {
    form: {
      storeId: {
        required
      },
      // banIdImage: {
      //   required
      // },
      // receiptImage: {
      //   required
      // }
    }
  }
}
</script>

<style scoped>
.theme--dark.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}

.col.col-12 {
  padding: 6px 12px;
}
</style>