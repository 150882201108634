import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        // primary: '#EF6079',
        primary: process.env.VUE_APP_THEME_PRIMARY_COLOR,
        // secondary: '#424242',
        secondary: process.env.VUE_APP_THEME_SECONDARY_COLOR,
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  }
});
