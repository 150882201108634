import { render, staticRenderFns } from "./CampaignList.vue?vue&type=template&id=c90de086&scoped=true"
import script from "./CampaignList.vue?vue&type=script&lang=js"
export * from "./CampaignList.vue?vue&type=script&lang=js"
import style0 from "./CampaignList.vue?vue&type=style&index=0&id=c90de086&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c90de086",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VContainer,VIcon,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
