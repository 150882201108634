<template>
  <v-container>

    <!-- Member Info -->
    <v-card outlined rounded="xl" class="mx-auto px-4 py-2">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-1">{{ user.displayName }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.phone }}</v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex align-center">
            <!-- <v-chip outlined color="#EF6079" class="ma-2 white--text">
              {{ user.email }}
            </v-chip> -->
            <span class="text-subtitle-2">{{ user.email }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="80">
          <v-img :src="user.picture" class="rounded-pill" v-if="user.picture"></v-img>
          <CustomIcon :src="require('@/assets/icons/member-settings.svg')" v-else></CustomIcon>
        </v-list-item-avatar>
      </v-list-item>
    </v-card>

    <!-- Action Buttons -->
    <v-row class="mt-12" justify="center">

      <v-col cols="4" class="my-6 d-flex justify-center">
        <v-btn depressed color="#FFFFFF22" @click="$router.push(`/admin/apply`)">
          <div class="d-flex flex-column pt-2">
            <CustomIcon :src="require('@/assets/icons/store-register.svg')" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">特店申請</span>
          </div>
        </v-btn>
      </v-col>

      <v-col cols="4" class="my-6 d-flex justify-center">
        <v-btn depressed color="#FFFFFF22" @click="signOut">
          <div class="d-flex flex-column pt-2">
            <CustomIcon :src="require('@/assets/icons/logout.svg')" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">登出</span>
          </div>
        </v-btn>
      </v-col>

      <v-col cols="4" class="my-6 d-flex justify-center" v-if="appEnv == 'dev'">
        <v-btn depressed color="#FFFFFF22" @click="testButton">
          <div class="d-flex flex-column pt-2">
            <CustomIcon :src="require('@/assets/icons/logout.svg')" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">測試按鈕(DEV)</span>
          </div>
        </v-btn>
      </v-col>

    </v-row>

    <v-footer fixed class="d-flex flex-column"
      style="padding-bottom:72px;background:transparent;">
      <span class="text-center text-caption">v{{ appVersion }}</span>
      <v-img v-if="platform == 'tycard'"
        class="mx-auto mt-3 mb-6" max-width="75%"
        :src="require('@/assets/img/typoint-logo.svg')">
      </v-img>
    </v-footer>
    
  </v-container>
</template>

<script>

export default {

  mounted() {
    console.log(`[Account] mounted: route data`, this.$route);
  },

  methods: {
    signOut() {

      this.$swal({
        background: '#424242',
        color: '#ffffff',
        html: '<span style="color:white">確定要登出特店管理後台嗎？</span>',
        icon: 'warning',
        confirmButtonText: '確定',
        showCloseButton: true
      })
      .then(result => {
        if (!result.isConfirmed) return;

        return this.$store.dispatch('signOut')
        .then(() => {
          //TODO: Go to entry page.
          // this.$router.replace('/signin')
        });
      });
    },

    testButton() {
      this.$store.commit('setStoresDataUpdateStatus', 'dirty');
      this.$router.push('/admin/stores');
    }
  },

  data() {
    return {
      
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    appVersion() {
      return process.env.VUE_APP_VERSION;
    },

    appEnv() {
      return process.env.VUE_APP_ENV;
    },
    platform() {
      return process.env.VUE_APP_PLATFORM;
    }
  }
}
</script>

<style scoped>
.theme--dark.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}

.v-btn {
  width: 96px;
  height: 96px !important;
}
</style>