var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v("基本資訊")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("商店招牌名稱"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("營業登記名稱"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Business Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.businessName),callback:function ($$v) {_vm.$set(_vm.form, "businessName", $$v)},expression:"form.businessName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("商店類型"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Store Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","items":_vm.storeCategoryOptions,"error-messages":errors},model:{value:(_vm.form.storeCategoryId),callback:function ($$v) {_vm.$set(_vm.form, "storeCategoryId", $$v)},expression:"form.storeCategoryId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-button"},[_vm._v("統一編號"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Ban ID","rules":"required|digits:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.banId),callback:function ($$v) {_vm.$set(_vm.form, "banId", $$v)},expression:"form.banId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-button"},[_vm._v("營業電話"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Phone","rules":"required|min:9|max:10|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("營業地址"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Address","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("Google商家")]),_c('GoogleMapPlaceSearchWidget',{attrs:{"init-place-id":_vm.form.placeId},on:{"change":_vm.onPlaceSelected}}),_c('span',{staticClass:"text-caption grey--text"},[_vm._v("1. 在地圖上方輸入框中輸入您的商店名稱")]),_c('br'),_c('span',{staticClass:"text-caption grey--text"},[_vm._v("2. 在出現的列表中點擊您的商店名稱")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v("聯絡資訊")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-button"},[_vm._v("負責人姓名"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Owner Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.ownerName),callback:function ($$v) {_vm.$set(_vm.form, "ownerName", $$v)},expression:"form.ownerName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-button"},[_vm._v("聯絡人姓名"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Contact Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.contactName),callback:function ($$v) {_vm.$set(_vm.form, "contactName", $$v)},expression:"form.contactName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-button"},[_vm._v("聯絡電話"),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Contact Phone","rules":"required|min:9|max:10|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.contactPhone),callback:function ($$v) {_vm.$set(_vm.form, "contactPhone", $$v)},expression:"form.contactPhone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("電子信箱")]),_c('validation-provider',{attrs:{"name":"Contact Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.contactEmail),callback:function ($$v) {_vm.$set(_vm.form, "contactEmail", $$v)},expression:"form.contactEmail"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v("帳務資訊")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("銀行代碼")]),_c('validation-provider',{attrs:{"name":"Bank Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","items":_vm.bankOptions,"error-messages":errors},model:{value:(_vm.form.bankCode),callback:function ($$v) {_vm.$set(_vm.form, "bankCode", $$v)},expression:"form.bankCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("分行代碼")]),_c('validation-provider',{attrs:{"name":"Branch Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","items":_vm.filteredBranchOptions,"error-messages":errors,"no-data-text":"無對應的分行"},model:{value:(_vm.form.branchCode),callback:function ($$v) {_vm.$set(_vm.form, "branchCode", $$v)},expression:"form.branchCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("銀行帳號")]),_c('validation-provider',{attrs:{"name":"Account Number","rules":"min:8|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.accountNumber),callback:function ($$v) {_vm.$set(_vm.form, "accountNumber", $$v)},expression:"form.accountNumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-button"},[_vm._v("戶名")]),_c('validation-provider',{attrs:{"name":"Account Name","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.accountName),callback:function ($$v) {_vm.$set(_vm.form, "accountName", $$v)},expression:"form.accountName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-button"},[_vm._v("身分證字號／統一編號")]),_c('validation-provider',{attrs:{"name":"Social ID / Business ID","rules":"min:8|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","required":"","error-messages":errors},model:{value:(_vm.form.idNo),callback:function ($$v) {_vm.$set(_vm.form, "idNo", $$v)},expression:"form.idNo"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("上傳存摺封面")]),_c('validation-provider',{attrs:{"name":"Bank Book Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"dense":"","rounded":"","filled":"","prepend-icon":"","prepend-inner-icon":"mdi-file-upload","show-size":"","counter":"","accept":"image/png, image/jpeg","truncate-length":"15","error-messages":errors,"placeholder":!!_vm.form.previewBankBookImageUrl? '上傳新的圖片將取代原有的': ''},model:{value:(_vm.form.bankBookImage),callback:function ($$v) {_vm.$set(_vm.form, "bankBookImage", $$v)},expression:"form.bankBookImage"}})]}}],null,true)}),(_vm.form.previewBankBookImageUrl)?_c('v-img',{staticClass:"rounded-xl",class:{ 'blur-effect': !!_vm.form.bankBookImage },attrs:{"src":_vm.form.previewBankBookImageUrl,"height":180}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("上傳統一編號章")]),_c('validation-provider',{attrs:{"name":"BAN Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"dense":"","rounded":"","filled":"","prepend-icon":"","prepend-inner-icon":"mdi-file-upload","show-size":"","counter":"","accept":"image/png, image/jpeg","truncate-length":"15","error-messages":errors,"placeholder":!!_vm.form.previewBanImageUrl? '上傳新的圖片將取代原有的': ''},model:{value:(_vm.form.banImage),callback:function ($$v) {_vm.$set(_vm.form, "banImage", $$v)},expression:"form.banImage"}})]}}],null,true)}),(_vm.form.previewBanImageUrl)?_c('v-img',{staticClass:"rounded-xl",class:{ 'blur-effect': !!_vm.form.banImage },attrs:{"src":_vm.form.previewBanImageUrl,"height":180}}):_vm._e()],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","large":"","rounded":"","color":"primary","loading":_vm.isProcessing,"disabled":_vm.isProcessing},on:{"click":_vm.submit}},[_vm._v(" 送出申請 ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }