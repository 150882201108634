// import axios from 'axios';
import _ from 'underscore';

const SSORequest = config => {
  return window.qcsso.request(config);
};

SSORequest.get = (url, options) => {
  return window.qcsso.request(
    _.extend({ method: 'get', url }, options)
  );
};

SSORequest.post = (url, data, options) => {
  return window.qcsso.request(
    _.extend({
      method: 'post',
      url,
      data
    }, options)
  );
};

SSORequest.put = (url, data, options) => {
  return window.qcsso.request(
    _.extend({
      method: 'put',
      url,
      data
    }, options)
  );
};

SSORequest.delete = (url, options) => {
  return window.qcsso.request(
    _.extend({
      method: 'delete',
      url
    }, options)
  );
};

export default SSORequest;