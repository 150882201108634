import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

import Entry from '@/pages/Entry.vue';

import Base from '@/pages/admin/Base.vue';
import StoreList from '@/pages/admin/stores/StoreList.vue';
import StoreInfo from '@/pages/admin/stores/StoreInfo.vue';
import StoreEdit from '@/pages/admin/stores/StoreEdit.vue';
import StoreManagers from '@/pages/admin/stores/StoreManagers.vue';
import StampCards from '@/pages/admin/stores/StampCards.vue';
import StampCardInfo from '@/pages/admin/stores/StampCardInfo.vue';
import StampCardEdit from '@/pages/admin/stores/StampCardEdit.vue';
import StampCardTransactions from '@/pages/admin/stores/StampCardTransactions.vue';
import Tickets from '@/pages/admin/stores/Tickets.vue';
import TicketHistory from '@/pages/admin/stores/TicketHistory.vue';
import Transactions from '@/pages/admin/stores/Transactions.vue';
import Withdrawals from '@/pages/admin/stores/Withdrawals.vue';
import Apply from '@/pages/admin/stores/Apply.vue';
import Withdraw from '@/pages/admin/stores/Withdraw.vue';

import CampaignList from '@/pages/admin/campaigns/CampaignList.vue';
import Campaign from '@/pages/admin/campaigns/Campaign.vue';

import Notifications from '@/pages/admin/Notifications.vue';
import Account from '@/pages/admin/Account.vue';
import ScannerCheckin from '@/pages/admin/ScannerCheckIn.vue';
import ScannerStamps from '@/pages/admin/ScannerStamps.vue';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/signin'
  },
  {
    path: '/signin',
    name: 'sign-in',
    component: Entry
  },
  {
    path: '/admin',
    redirect: '/admin/stores',
    name: 'admin',
    component: Base,
    children: [
      
      {
        path: 'stores',
        name: 'store-list',
        component: StoreList
      },
      {
        path: 'stores/:storeId/info',
        name: 'store-info',
        component: StoreInfo,
        props: true,
        meta: {
          showGoBack: true,
          goBackTo: 'store-list',
          appBarIconBtn: true
        }
      },
      {
        path: 'stores/:storeId/edit',
        name: 'store-edit',
        component: StoreEdit,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          authRoles: [ 1 ]
        }
      },
      {
        path: 'stores/:storeId/managers',
        name: 'store-managers',
        component: StoreManagers,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          authRoles: [ 1 ]
        }
      },
      {
        path: 'stores/:storeId/transactions',
        name: 'transactions',
        component: Transactions,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true
        }
      },
      {
        path: 'stores/:storeId/withdrawals',
        name: 'withdrawals',
        component: Withdrawals,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true
        }
      },
      {
        path: 'stores/:storeId/withdraw',
        name: 'withdraw',
        component: Withdraw,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          titleAppendTextByQuery: 'reApplication: - 補件',
          authRoles: [ 1, 3 ]
        }
      },

      {
        path: 'stores/:storeId/stamp-cards',
        name: 'store-stamp-cards',
        component: StampCards,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true
        }
      },
      {
        path: 'stores/:storeId/create-stamp-card',
        name: 'store-stamp-card-create',
        component: StampCardEdit,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          authRoles: [ 1 ]
        }
      },
      {
        path: 'stores/:storeId/stamp-cards/:stampCardId/info',
        name: 'store-stamp-card-info',
        component: StampCardInfo,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true
        }
      },
      {
        path: 'stores/:storeId/stamp-cards/:stampCardId/edit',
        name: 'store-stamp-card-edit',
        component: StampCardEdit,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          authRoles: [ 1 ]
        }
      },
      {
        path: 'stores/:storeId/stamp-cards/:stampCardId/transactions',
        name: 'store-stamp-card-transactions',
        component: StampCardTransactions,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true
        }
      },
      {
        path: 'stores/:storeId/tickets',
        name: 'store-tickets',
        component: Tickets,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true
        }
      },
      {
        path: 'stores/:storeId/ticket-history',
        name: 'store-ticket-history',
        component: TicketHistory,
        props: true,
        meta: {
          showGoBack: true,
          hideButtomNav: true
        }
      },
      
      // {
      //   path: 'stores/:storeId/stamp-card-transactions',
      //   name: 'store-stamp-card-transactions',
      //   component: () => import('@/pages/admin/stores/StampCardTransactions.vue'),
      //   props: true,
      //   meta: {
      //     showGoBack: true,
      //     hideButtomNav: true
      //   }
      // },

      // {
      //   path: 'transactions',
      //   name: 'transactions',
      //   component: Transactions,
      //   meta: {
      //     showGoBack: true,
      //     hideButtomNav: true,
      //     titleUseStoreName: true
      //   }
      // },
      // {
      //   path: 'withdraw',
      //   name: 'withdraw',
      //   component: Withdraw,
      //   meta: {
      //     showGoBack: true,
      //     hideButtomNav: true,
      //     titleAppendTextByQuery: 'reApplication: - 補件',
      //     authRoles: [ 1, 3 ]
      //   }
      // },
      {
        path: 'apply',
        name: 'apply',
        component: Apply,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          titleAppendTextByQuery: 'reApplication: - 補件'
        }
      },

      /// Campaigns
      {
        path: 'campaigns',
        name: 'campaign-list',
        component: CampaignList,
      },
      {
        path: 'campaigns/:campaignId',
        name: 'campaign-stats',
        props: true,
        component: Campaign,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          titleUseCampaignName: true
        }
      },
      
      /// Others
      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
          showGoBack: true,
          hideButtomNav: true,
          appBarTextBtnLabel: '全部已讀'
        }
      },
      {
        path: 'account',
        name: 'account',
        component: Account,
      },
      {
        path: 'scanner-checkin',
        name: 'scanner-checkin',
        component: ScannerCheckin,
      },
      {
        path: 'scanner-stamps',
        name: 'scanner-stamps',
        component: ScannerStamps
      }
    ]
  }


  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
